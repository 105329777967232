import { getCookie, setCookie } from '../../../util/cookie';

async function makeApiRequest(route: string, method?: string, data?: any) {
  try {
    const accessToken = getCookie('access_token');
    let baseOptions = {
      method: method ?? 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'auth-provider': 'auth0'
      }
    };

    let options = {};

    if (data) {
      options = {
        ...baseOptions,
        body: JSON.stringify(data)
      };
    } else {
      options = baseOptions;
    }

    const req = await fetch(`${process.env.GATSBY_API_URL}/${route}`, options);

    if (!req.ok) {
      const err = await req.json();
      return err;
    }

    return await req.json();
  } catch (err) {
    return err;
  }
}

export async function refreshToken(email: string) {
  const data = await makeApiRequest('refreshToken', 'POST', { email });
  setCookie('access_token', data?.access_token);
}

export async function clearImpersonate(adminUserId: string) {
  return await makeApiRequest('clearImpersonate', 'POST', { adminUserId });
}

export async function updateProfile(body: any) {
  return await makeApiRequest('profile', 'POST', body);
}

export const getAffiliationsForUser = async () => {
  return await makeApiRequest('affiliations');
};

export const applyToEvent = async (eventId: string) => {
  return await makeApiRequest('applyToEvent', 'POST', { eventId });
};

export const eventSectionsPurchase = async (body: {
  type: 'PO' | 'CREDIT_CARD';
  items: any[];
}) => {
  return await makeApiRequest('eventSectionsPurchase', 'POST', body);
};

export const eventApplicationPurchase = async (body: { id: string }) => {
  return await makeApiRequest('eventApplicationPurchase', 'POST', body);
};

export const getEventPayment = async (eventPaymentId: string) => {
  return await makeApiRequest(
    `eventPayment?eventPaymentId=${eventPaymentId}`,
    'GET'
  );
};

export const finishEventPayment = async (
  eventPaymentId: string,
  batchNumber: string,
  transactionNumber: string,
  userId: string,
  eventTicketRequestId: string
) => {
  return await makeApiRequest(`eventPayment`, 'POST', {
    eventPaymentId,
    batchNumber,
    transactionNumber,
    userId,
    eventTicketRequestId
  });
};

export const cancelEventPayment = async (eventPaymentId: string) => {
  return await makeApiRequest(`eventPayment`, 'DELETE', { eventPaymentId });
};

export const submitPurchaseOrderForInstitute = async (body: any) => {
  return await makeApiRequest('eventPurchaseOrders', 'POST', body);
};

export const getApplicantsForPuchaseOrder = async ({
  instituteId
}: {
  instituteId: string;
}) => {
  return await makeApiRequest(
    `eventPurchaseOrders?getAvailableUsersCountForApplicants=true&instituteId=${instituteId}`,
    'GET'
  );
};

export const checkApplicantForPuchaseOrder = async ({
  instituteId,
  email
}: {
  instituteId: string;
  email: string;
}) => {
  const emailEncoded = encodeURIComponent(email);
  return await makeApiRequest(
    `eventPurchaseOrders?checkUserForApplicants=${emailEncoded}&instituteId=${instituteId}`,
    'GET'
  );
};

export const getCurrentYearInstitutes = async () => {
  return await makeApiRequest('currentYearInstitutes');
};

export const getEventApplications = async () => {
  return await makeApiRequest('eventApplications');
};

export const getLegacyEventApplications = async () => {
  return await makeApiRequest('portalEvents');
};

export const getConferenceDaysEvents = async () => {
  return await makeApiRequest('eventSectionEvents');
};

export const getEventTicketHolders = async () => {
  return await makeApiRequest('eventTicketHolder');
};

export const updateCartAndReloadUser = async (cart: any, auth: any) => {
  await updateCart(cart);
  await auth.reloadUser();
};

export const updateCart = async (cart: any) => {
  return await makeApiRequest('userCart', 'POST', { cart });
};

export const getLegacyEventPaymentLink = async (eventId: string) => {
  return await makeApiRequest(`legacyPaymentLink?eventId=${eventId}`);
};

export const getEvent = async (eventId: string) => {
  return await makeApiRequest(`event?id=${eventId}`);
};

export const getEvents = async (search?: string, year?: string) => {
  return await makeApiRequest(
    `events?search=${search}${year ? `&year=${year}` : ''}`
  );
};

export const addHistoryEvent = async (data: any) => {
  return await makeApiRequest(`eventHistory`, 'POST', data);
};

export const editHistoryEvent = async (data: any) => {
  return await makeApiRequest(`eventHistory`, 'PUT', data);
};

export const deleteHistoryEvent = async (data: any) => {
  return await makeApiRequest(`eventHistory`, 'DELETE', data);
};

export const getEventApplication = async (applicationId: string) => {
  return await makeApiRequest(
    `eventApplications?applicationId=${applicationId}`
  );
};

export const getEventApplicationByEventId = async (eventId: string) => {
  return await makeApiRequest(`eventApplications?eventId=${eventId}`);
};
export const updateEventApplicationPaymentMethod = async (data: any) => {
  return await makeApiRequest(`eventApplications`, 'PATCH', data);
};

export const updateContentAccess = async (data: any) => {
  return await makeApiRequest(`contentAccess`, 'PATCH', data);
};

export const updateOrganizationMembership = async (id: string, data: any) => {
  return await makeApiRequest(`organizationMembership?id=${id}`, 'PATCH', data);
};

export const getOrganizationMembership = async (organizationId: string) => {
  return await makeApiRequest(
    `organizationMembership?organizationId=${organizationId}`,
    'GET'
  );
};

export const updateEventApplication = async (data: any) => {
  return await makeApiRequest('eventApplications', 'PUT', data);
};

export const getPackageRequestById = async (packageRequestId: string) => {
  return await makeApiRequest(
    `packageRequest?packageRequestId=${packageRequestId}`
  );
};

export const getOrganization = async (id: string) => {
  return await makeApiRequest(`organization?id=${id}`);
};

export const getOrganizations = async ({ search }: { search?: string }) => {
  if (search) {
    return await makeApiRequest(`organizations?search=${search}`);
  }
  return await makeApiRequest('organizations');
};

export const getOrganizationsForSelfAffiliation = async (search: string) => {
  return await makeApiRequest(
    `organizations?isForSelfAffiliation=true&search=${search}`
  );
};

export const createOwnOrganizationAffiliation = async (data: any) => {
  return await makeApiRequest('organizationOwnAffliation', 'POST', data);
};

export const editOwnOrganizationAffiliation = async (data: any) => {
  return await makeApiRequest('organizationOwnAffliation', 'PUT', data);
};

export const deleteOwnOrganizationAffiliation = async (data: any) => {
  return await makeApiRequest('organizationOwnAffliation', 'DELETE', data);
};

export const updateOrganizationInfo = async (
  organizationId: string,
  data: { type: any; values: any }
) => {
  return await makeApiRequest(`organization?id=${organizationId}`, 'PUT', data);
};

export const createOwnOrganization = async (data: any) => {
  return await makeApiRequest(`organization`, 'POST', data);
};

export const getPackageRequests = async (organizationId: string) => {
  return await makeApiRequest(
    `packageRequests?organizationId=${organizationId}`
  );
};

export const getSchoolYears = async () => {
  return await makeApiRequest('schoolYears');
};

export const getAvailablePackages = async (
  schoolYearId: string,
  organizationId: string
) => {
  return await makeApiRequest(
    `availablePackages?schoolYearId=${schoolYearId}&organizationId=${organizationId}`
  );
};

export const initiatePackageRequest = async (data: any) => {
  return await makeApiRequest('packageRequests', 'POST', data);
};

export const getEventSections = async () => {
  return await makeApiRequest('eventSections', 'GET');
};

export const updatePackageRequest = async (data: any) => {
  return await makeApiRequest('packageRequest', 'PATCH', data);
};

export const submitUserOrganizationInvitation = async (body: any) => {
  return await makeApiRequest('userOrganizationInvite', 'PUT', body);
};

export const getUserOrganizationInvitation = async (code: string) => {
  return await makeApiRequest(`userOrganizationInvite?code=${code}`);
};

export const submitUserDistrictInvitation = async (body: any) => {
  return await makeApiRequest('userDistrictInvite', 'PUT', body);
};

export const getUserDistrictInvitation = async (code: string) => {
  return await makeApiRequest(`userDistrictInvite?code=${code}`);
};

export const getGrades = async () => {
  return await makeApiRequest('grades');
};

export const getPositions = async () => {
  return await makeApiRequest('positions');
};

export const getOrganizationTypes = async () => {
  return await makeApiRequest('organizationTypes');
};

export const getDistricts = async ({ search }: { search?: string }) => {
  if (search) {
    return await makeApiRequest(`districts?search=${search}`);
  }
  return await makeApiRequest('districts');
};

export const createUserOrganizationInvitation = async (body: any) => {
  return await makeApiRequest('userOrganizationInvite', 'POST', body);
};

export const deleteUserFromOrganization = async (body: any) => {
  return await makeApiRequest('userOrganizationInvite', 'DELETE', body);
};

export const getPackageRequestTotals = async (
  packageRequestId: string,
  body: any
) => {
  return await makeApiRequest(
    `packageRequestTotals?packageRequestId=${packageRequestId}`,
    'POST',
    body
  );
};

export const getTicketAssignments = async (packageRequestId: string) => {
  return await makeApiRequest(
    `ticketAssignments?packageRequestId=${packageRequestId}`
  );
};

export const getConferenceDayTicketAssignments = async (
  eventPaymentId: string,
  eventId: string
) => {
  return await makeApiRequest(
    `conferenceDayTicketAssignments?eventPaymentId=${eventPaymentId ||
      ''}&eventId=${eventId || ''}`
  );
};

export const submitAssignConferenceDayTickets = async (
  data: any,
  eventPaymentId: string
) => {
  return await makeApiRequest(
    `conferenceDayTicketAssignments?eventPaymentId=${eventPaymentId}`,
    'POST',
    data
  );
};

export const getEventTicketAssignments = async (eventPaymentId: string) => {
  return await makeApiRequest(
    `eventTicketAssignments?eventPaymentId=${eventPaymentId || ''}`
  );
};

export const submitAssignEventTickets = async (
  data: any,
  eventPaymentId: string
) => {
  return await makeApiRequest(
    `eventTicketAssignments?eventPaymentId=${eventPaymentId}`,
    'POST',
    data
  );
};

export const submitAssignTickets = async (
  data: any,
  packageRequestId: string
) => {
  return await makeApiRequest(
    `ticketAssignments?packageRequestId=${packageRequestId}`,
    'POST',
    data
  );
};

export const getPackageRequestOrganizationUsers = async (
  packageRequestId: string
) => {
  return await makeApiRequest(
    `packageRequestOrganizationUsers?packageRequestId=${packageRequestId}`
  );
};

export const createUserWithAcceptedInvitation = async (data: any) => {
  return await makeApiRequest(`createUserWithAcceptedMembership`, 'POST', data);
};

export const getContractsByOrgnizationId = async (organizationId: string) => {
  return await makeApiRequest(`contracts?organizationId=${organizationId}`);
};

export const getEventResources = async ({
  eventId,
  eventSectionId,
  eaId
}: {
  eventId: string;
  eventSectionId?: string;
  eaId?: string;
}) => {
  return await makeApiRequest(
    `eventResources?eventId=${eventId}${
      eventSectionId ? `&eventSectionId=${eventSectionId}` : ''
    }
    ${eaId ? `&eaId=${eaId}` : ''}`
  );
};

export const getOrganizationResources = async ({
  schoolYearId,
  organizationId
}: {
  schoolYearId: string;
  organizationId: string;
}) => {
  return await makeApiRequest(
    `organizationResources${
      schoolYearId ? `?schoolYearId=${schoolYearId}` : ''
    }${
      organizationId
        ? schoolYearId
          ? `&organizationId=${organizationId}`
          : `?organizationId=${organizationId}`
        : ''
    }
    `
  );
};
